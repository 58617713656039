import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translation_en from './en';
import translation_ru from './ru';
import { createTranslationTable, flattenObject } from './until';


const resources = {
  en: translation_en,
  ru: translation_ru,
};
const enFlattened = flattenObject(translation_en);
const ruFlattened = flattenObject(translation_ru);

export const translationTable = createTranslationTable(
  [
    enFlattened,
    ruFlattened

  ],
  ['English', 'Russian'],
);
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      lookupLocalStorage: 'lng',
    },
    supportedLngs: ['en', 'ru'],
    resources,
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
