import i18n from '@/locales/config';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { App, ConfigProvider, ConfigProviderProps, theme } from 'antd';
import enUS from 'antd/locale/en_US';
import zhCN from 'antd/locale/zh_CN';
import zh_HK from 'antd/locale/zh_HK';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import weekday from 'dayjs/plugin/weekday';
import React, { ReactNode, useEffect, useState } from 'react';
import { ThemeProvider, useTheme } from './components/theme-provider';
import { TooltipProvider } from './components/ui/tooltip';
import storage from './utils/authorization-util';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const AntLanguageMap = {
  en: enUS,
  zh: zhCN,
  'zh-TRADITIONAL': zh_HK,
};

const queryClient = new QueryClient();

type Locale = ConfigProviderProps['locale'];

function Root({ children }: React.PropsWithChildren) {
  const { theme: themeragflow } = useTheme();
  const getLocale = (lng: string) =>
    AntLanguageMap[lng as keyof typeof AntLanguageMap] ?? enUS;

  const [locale, setLocal] = useState<Locale>(getLocale(storage.getLanguage()));

  i18n.on('languageChanged', function (lng: string) {
    storage.setLanguage(lng);
    setLocal(getLocale(lng));
  });

  const [isCustomTheme, setIsCustomTheme] = useState(false);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (document.body.classList.contains('samrukTheme')) {
        setIsCustomTheme(true);
      } else {
        setIsCustomTheme(false);
      }
    });

    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['class'],
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const customTheme = {
    token: {
      fontFamily: 'Inter',
      colorPrimary: '#3d4a83',
    },
  };

  const defaultTheme = {
    token: {
      fontFamily: 'Inter',
    },
    algorithm:
      themeragflow === 'dark' ? theme.darkAlgorithm : theme.defaultAlgorithm,
  };

  return (
    <>
      <ConfigProvider
        // theme={{
        //   token: {
        //     fontFamily: 'Inter',
        //   },
        //   algorithm:
        //     themeragflow === 'dark'
        //       ? theme.darkAlgorithm
        //       : theme.defaultAlgorithm,
        // }}
        theme={isCustomTheme ? customTheme : defaultTheme}
        locale={locale}
      >
        <App> {children}</App>
      </ConfigProvider>
      <ReactQueryDevtools buttonPosition={'top-left'} />
    </>
  );
}

const RootProvider = ({ children }: React.PropsWithChildren) => {
  useEffect(() => {
    // Because the language is saved in the backend, a token is required to obtain the api. However, the login page cannot obtain the language through the getUserInfo api, so the language needs to be saved in localstorage.
    const lng = storage.getLanguage();
    if (lng) {
      i18n.changeLanguage(lng);
    }

    // Яндекс Метрика
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
          (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
          m[i].l=1*new Date();
          for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
          k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
          (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

          ym(99117764, "init", {
          clickmap:true,
          trackLinks:true,
          accurateTrackBounce:true,
          webvisor:true
      });
      `;
    document.head.appendChild(script);

    // Яндекс Метрика - noscript
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `
           <div><img src="https://mc.yandex.ru/watch/99117764" style="position:absolute; left:-9999px;" alt="" /></div>
        `;
    document.body.appendChild(noscript);

    // Google Analytics
    const ga_script = document.createElement('script');
    ga_script.type = 'text/javascript';
    ga_script.async = true;
    ga_script.src = `https://www.googletagmanager.com/gtag/js?id=G-ZBQ80299MR`;
    document.head.appendChild(ga_script);

    ga_script.onload = () => {
      (window as any).dataLayer = (window as any).dataLayer || [];
      function gtag(...args: any[]) {
        (window as any).dataLayer.push(args);
      }
      gtag('js', new Date());
      gtag('config', 'G-ZBQ80299MR');
    };

    return () => {
      document.head.removeChild(script);
      document.body.removeChild(noscript);
      document.head.removeChild(ga_script);
    };
  }, []);

  return (
    <TooltipProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider defaultTheme="light" storageKey="ragflow-ui-theme">
          <Root>{children}</Root>
        </ThemeProvider>
      </QueryClientProvider>
    </TooltipProvider>
  );
};
export function rootContainer(container: ReactNode) {
  return <RootProvider>{container}</RootProvider>;
}
